<script>
export default {
  name: "MapboxPositionTimeData",
  props: {
    index: {
      type: Number,
      default: null,
    },
    location: {
      type: Object,
      default: null,
    },
    rotate: {
      type: Number,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
